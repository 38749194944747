import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { urlBase } from "../authConfig";
import Loading from '../animations/Loading.json'
import { Player } from '@lottiefiles/react-lottie-player';

//const stripePromise = loadStripe('pk_test_51Pr0drI3uw0UqnHM4hfJW96TnAmCQLtl9LqqzZGGSUbHrOedr1BiaCGHNFvhkjdqmYgPU6hHXwowe4qn4zOuRj1e00qrV13Aog');
const stripePromise = loadStripe('pk_live_51Pr0drI3uw0UqnHMrSWPPpPC7Mpadn0ErmT8Y9NxmeBfJyiz7LnqWx1L8OFSqih9TqsPr3WRu7tld3Sy9Vtjc8WA00diG8qpIh');

const Payment = () => {
    const { accessToken, idPriceStripe } = useStateContext();
    const [clientSecret, setClientSecret] = useState(null);

    const getData = useCallback(async () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        try {
            const response = await fetch(`${urlBase}/PaymentSub`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    idPrice: idPriceStripe
                })
            });
            const data = await response.json();
            return data.clientSecret;
        } catch (error) {
            console.log('Error al obtener clientSecret:', error);
        }
    }, [accessToken]);

    useEffect(() => {
        getData().then(clientSecret => {
            if (typeof clientSecret === 'string') {
                setClientSecret(clientSecret);
            } else {
                console.error('Error: clientSecret no es una cadena válida', clientSecret);
            }
        });
    }, [getData]);

    const options = clientSecret ? { clientSecret } : null;

    return (
        <>
            {options ?
                <>
                    <div className="w-full flex justify-center">
                        <div id="checkout" className="w-[1000px]">
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={options}
                            >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </div>
                    </div>
                </>
                :
                <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-neutral-5'></div>
                    <div className='w-full h-full flex justify-center items-center pr-[15%]'>
                        <Player
                            className='w-[20%]'
                            autoplay
                            loop
                            src={Loading}
                        >
                        </Player>
                    </div>
                </div>
            }
        </>
    );
}

export default Payment;
