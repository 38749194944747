import React ,{useEffect} from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import {FiSettings} from 'react-icons/fi'
import { Sidebar, Navbar} from './components'
import { useStateContext } from './contexts/ContextProvider'
import { WelcomeScreen, UploadbankStatement, BanksEditionScreen, SummaryScreen, ClassificationScreen, ReportScreen, SubcategoriesEditionScreen, Saldos, HomePage, RulesScreen, TestChart, Syncfy, SelectClientScreen, ClassificationOnlyViewScreen, FourOFour, Transacciones, SubcategoriesLuisIdea, CheckerSaldos, ChartSubPage, ChartLineSubPage, TotalSubPage, AdministrationScreen, ReportPlanning, PlanningTransaction, UploadPlanning, ControlCenterScreen, PrivacyPolicy, TermsAndConditions, Payment, Success } from './pages';

import { useIsAuthenticated } from "@azure/msal-react";

import './App.css'
import ComparisonPlanning from './pages/ComparisonPlanning'

const App = () => {
    
    const isAuthenticated = useIsAuthenticated();
    const {activeMenu , setMode, currentMode,idClient,userAccess,idRS} = useStateContext()
    let theme = currentMode === 'Dark' ? 'dark' : '' 
    return (
    <div className='bg-total-white h-[calc(100vh)]' data-clarity-unmask="true">
        {<BrowserRouter>
        <div>
            <div className={'flex relative bg-main-bg dark:bg-main-dark-bg '+ theme}>
            
                {idClient && isAuthenticated && location.pathname!='/control' ?  (/*activeMenu ? */(
                    <>
                    
                    <div className='w-[15%] fixed sidebar h-screen  z-[100] dark:bg-secondary-dark-bg '>
                        <Sidebar/>
                    </div>
                    <div className='min-w-[15%] h-screen'>

                    </div>
                    </>
                ) /*}: (
                    <div className='w-[20%] sidebar dark:bg-secondary-dark-bg fixed left-0'>
                            <Sidebar/>
                        </div>
                )*/) : (<></>)}
                {/*<div className={
                    `dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? ' md:ml-72'
                    : ' flex-2'}`
                }>
                <div className='fixed md:static bg-main-bg dark_bg-main-dark-bg navbar w-full'>
                <Navbar/>
                </div>
                </div>*/}
                <div className='dark:bg-main-dark-bg w-[100%] pr-0 mr-0 overflow-x-hidden bg-total-white overflow-y-scroll relative'  >
                    {idClient && isAuthenticated && location.pathname!='/control' ?
                        <><div className=' bg-white z-[99] w-[85%]  fixed'>
                        <Navbar/>
                        </div>
                        <div className='h-[80px] 2xl:h-[100px] sticky top-0 '>

                        </div></>
                        :null
                    }
                    <Routes>
                        <Route exact path='/' element={<WelcomeScreen/>}/>
                        
                        
                        {idClient && userAccess && 
                            <>
                            
                            
                            {userAccess.access1 && <>
                                <Route exact path="/users" element={<><AdministrationScreen/></>}/>
                                <Route exact path="/home" element={<HomePage/>} />
                                <Route exact path='/rules' element={<RulesScreen/>}/>
                                </>
                            }
                            
                            {userAccess.access4 && <>
                                <Route exact path="/banksedition" element={<BanksEditionScreen/>}/>
                                <Route exact path="/balance" element={<Saldos/>}/>
                                </>
                            }
                            {userAccess.access3 && <>
                                <Route exact path="/upload" element={<UploadbankStatement/>}/>
                                <Route exact path='/planning/upload' element={<UploadPlanning />} />
                                </>
                            }
                            {userAccess.access2 && 
                                <Route exact path="/classification" element={<ClassificationScreen/>}/>
                            }
                            {userAccess.access5 &&
                                <Route exact path="/subcategories" element={<SubcategoriesLuisIdea/>}/>
                            }
                            {userAccess.access7 &&
                                 <Route exact path='/Syncfy' element={<Syncfy/>}/>
                            }
                            {userAccess.access9 &&
                                <Route exact path="/report" element={<ReportScreen/>}/>

                            }
                            {userAccess.access6 &&
                                <>
                                <Route exact path="/planning" element={<ReportPlanning/>}/>
                                <Route exact path="/planning/comparison" element={<ComparisonPlanning/>}/>
                                <Route exact path='/planning/classification' element={<PlanningTransaction />} />
                                </>


                            }
                            {userAccess.access8 &&
                            <>
                            </>
                            }
                        </>
                        }
                        <Route path="/info" Component={()=>{window.location.replace( 'https://iex0hce4qfc.typeform.com/to/GLuSpQBZ') ;
                            return null;}}/>
                        <Route exact path="/summary" element={<SummaryScreen/>}/>
                        <Route exact path='/Payment' element={<Payment />} />
                        <Route exact path='/success' element={<Success />} />
                        <Route exact path='/summary/entradaEfectivo' element={<ChartSubPage/>}/>
                        <Route exact path='/summary/salidaEfectivo' element={<ChartSubPage/>}/>
                        <Route exact path='/summary/balanceBancos' element={<ChartLineSubPage/>}/>
                        <Route exact path='/summary/balanceCredito' element={<ChartLineSubPage/>}/>
                        <Route exact path='/SelectClient' element={<SelectClientScreen/>}/>
                        <Route exact path='/error' element={<FourOFour/>} />
                        <Route exact path='/balance/checker' element={<CheckerSaldos />} />
                        <Route exact path='/summary/FlujoCash' element={<TotalSubPage />} />
                        <Route exact path='/summary/CajaNeta' element={<TotalSubPage />} />
                        <Route exact path="/control" element={<ControlCenterScreen/>}/>
                        <Route exact path="/TyC" element={<TermsAndConditions/>}/>
                        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
                        {/*<Route exact path='/TestGraph' element={<TestChart/>}/>
                        <Route exact path='/transacciones' element={<Transacciones/>} />*/}
                    </Routes>
                </div>
            </div>
        </div>
        </BrowserRouter>}
    </div>
  )
}

export default App